<template>
    <div class="aside">
        <div class="banner">
            <img :src=getImgView(Alldata.imageUrl) alt="">
        </div>
        <div class="content" v-if="partList[0].state">
            <h4>注册参会：</h4>
            <div>
                <span style="margin-right: 10px;">{{
                    conferenceContactWay.contactWayAttendeeName }}</span>
                <span>{{ conferenceContactWay.contactWayAttendeeEmail
                }}</span>
            </div>
            <h4>专题投稿：</h4>
            <div>
                <span style="margin-right: 10px;">{{
                    conferenceContactWay.contactWayContributeName }}</span>
                <span>{{
                    conferenceContactWay.contactWayContributeEmail }}</span>
            </div>
            <h4>会议赞助：</h4>
            <div>
                <span style="margin-right: 10px;">{{
                    conferenceContactWay.contactWaySponsorName }}</span>
                <span>{{ conferenceContactWay.contactWaySponsorEmail
                }}</span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    // data() {
    // }
    mounted() {
        this.getDate()
    },
}
</script>

<style lang="less" scoped>
.aside {
    width: 300px;
    margin-top: 30px;
    padding-bottom: 100px;
    flex-shrink: 0;

    .banner {
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .content {
        margin-top: 20px;
        font-size: 14px;
        color: #666666;

        h4 {
            margin: 10px 0;
        }
    }

}
</style>