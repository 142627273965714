<template>
    <div class="contact">
        <!-- 联系方式 -->
        <div class="center">
            <aside-part></aside-part>
            <div class="content">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>联系方式</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">
                    <div class="title_zh">联系方式</div>
                    <div class="title_en">contact information</div>
                </div>
                <!-- <div class="box">
                    <img src="@/assets/ing.png" alt="">
                    <p>内容更新中...</p>
                </div> -->
                <div class="contactContent">
                    <el-descriptions class="margin-top" :labelStyle="{ 'width': '160px' }"
                        :contentStyle="{ 'width': '160px' }" title="注册参会" :column="3" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                联系人
                            </template>
                            {{ conferenceContactWay.contactWayAttendeeName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-message"></i>
                                邮箱
                            </template>
                            {{ conferenceContactWay.contactWayAttendeeEmail
                            }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions class="margin-top" :labelStyle="{ 'width': '160px' }"
                        :contentStyle="{ 'width': '160px' }" title="专题投稿" :column="3" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                联系人
                            </template>
                            {{
                                conferenceContactWay.contactWayContributeName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-message"></i>
                                邮箱
                            </template>
                            {{
                                conferenceContactWay.contactWayContributeEmail }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions class="margin-top" :labelStyle="{ 'width': '160px' }"
                        :contentStyle="{ 'width': '160px' }" title="会议赞助" :column="3" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                联系人
                            </template>
                            {{
                                conferenceContactWay.contactWaySponsorName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-message"></i>
                                邮箱
                            </template>
                            {{ conferenceContactWay.contactWaySponsorEmail
                            }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import asidePart from '@/components/asidePart.vue';
export default {
    name: 'contactInformation',
    components: {
        asidePart
    },
    mounted() {
        this.getDate();
    },
}
</script>

<style lang="less" scoped>
.center {
    display: flex;
    align-items: top;
    flex-shrink: 0;

    .content {
        padding: 30px;
        width: calc(100% - 60px);

        .title {
            margin-top: 20px;
        }

        .box {
            width: 100%;
            height: 500px;
            text-align: center;
            font-size: 16px;
            color: #0b88e0;
            opacity: 0.5;

            img {
                width: 300px;
            }
        }

        .contactContent {
            // font-size: 18px;
            // color: #666666;

            // h4 {
            //     margin: 10px 0;
            // }
            .margin-top {
                margin-bottom: 30px;
            }
        }
    }
}
</style>